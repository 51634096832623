"use client"

import clsx from "clsx"
import { useState } from "react"
import { ProductListQuery, formatQuery } from "src/components/(landing)/common/query"
import { Spinner } from "src/components/icons"
import { A } from "src/components/unstyled/A"

export type PaginationButtonProps = {
  parentPath: string
  query: ProductListQuery
  page: number
}

export const PaginationButton = ({ parentPath, query: oldQuery, page }: PaginationButtonProps) => {
  const [clicked, setClicked] = useState(false)
  const newQuery = { ...oldQuery, page }
  const newQueryString = formatQuery(newQuery)
  const href = parentPath + (newQueryString ? `/${newQueryString}` : "")
  const activePage = oldQuery.page || 1
  const active = page === activePage
  const Element = active ? "span" : A

  return (
    <Element
      key={page}
      href={href}
      onClick={active ? undefined : () => setClicked(true)}
      className={clsx("flex items-center justify-center w-10 h-10 text-lg font-semibold", {
        "text-muted hover:text-subtle": !active,
        "text-default hover:text-subtle": active,
      })}
    >
      {clicked ? <Spinner /> : page}
    </Element>
  )
}
