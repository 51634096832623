import { isCoarsePointer } from "./device"

export function scrollToTop() {
  window.scrollTo({ top: 0 })
}

export function scrollIntoView(element: Element, options?: ScrollIntoViewOptions) {
  const mobile = isCoarsePointer()

  element.scrollIntoView?.({
    behavior: mobile ? "instant" : "smooth",
    block: mobile ? "start" : "nearest",
    ...options,
  })
}

export function scrollToStartOf(element: Element, options?: ScrollIntoViewOptions) {
  element.scrollIntoView?.({
    behavior: "smooth",
    block: "start",
    ...options,
  })
}
