"use client"

import { ComponentProps } from "react"
import { reportError } from "src/utilities/error"
import { scrollToStartOf } from "src/utilities/scroll"

export type ScrollButtonProps = ComponentProps<"button"> & {
  targetId: string
}

export const ScrollButton = ({ targetId, ...props }: ScrollButtonProps) => {
  const scroll = () => {
    const element = document.getElementById(targetId)

    if (element) {
      scrollToStartOf(element)
    } else {
      reportError(`ScrollButton: Element with id "${targetId}" not found`)
    }
  }

  return (
    <button type="button" {...props} onClick={scroll}>
      {props.children}
    </button>
  )
}
