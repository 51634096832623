"use client"

import { A, AProps } from "src/components/unstyled/A"
import { trackProductLinkClick } from "src/tracking/api"
import { StorefrontListProduct } from "src/types/global"

export type ProductLinkProps = AProps & {
  listId: string
  product: StorefrontListProduct
}

/**
 * Use this component to link to product detail pages.
 */
export const ProductLink = ({ listId, product, ...props }: ProductLinkProps) => {
  const onClick = () => {
    trackProductLinkClick(listId, product)
    props.onClick?.()
  }

  return (
    <A {...props} onClick={onClick}>
      {props.children}
    </A>
  )
}
