"use client"

import { useTrackViewItemList } from "src/tracking/api"
import { StorefrontListProduct } from "src/types/global"

export type TrackViewItemListProps = {
  listId: string
  products: StorefrontListProduct[]
}

export function TrackViewItemList({ listId, products }: TrackViewItemListProps) {
  const ref = useTrackViewItemList(listId, products)

  return <div ref={ref} />
}
